import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalCtx } from './GlobalContext';
import useTopicsPageData from '../hooks/useTopicsPageData';
import { checkUrl } from '../components/Data';
import loadMore from '../hooks/loadMore';

export const TopicsPageCtx = createContext();

const TopicsCtx = ({ children }) => {
  const { lang } = useContext(GlobalCtx);
  const { topic } = useParams();
  const [offset, setOffset] = useState(6);
  const [ endOfList, setEndOfList ] = useState(false);

  let multiTopic = topic;

    const connectTopic = () => {
        if(topic === 'lfe' || topic === 'sol') {
            multiTopic = 'lfe,sol';
        }
        if(topic === 'fam' || topic === 'edu') {
            multiTopic = 'fam,edu';
        }
        if(topic === 'pol' || topic === 'pat') {
            multiTopic = 'pol,pat';
        }
        return multiTopic;
    }

    useEffect(() => {
        connectTopic();
    }, [topic]);

  const { petitionsByTopic, setPetitionsByTopic } = useTopicsPageData(
    `${checkUrl()}/api/v1/petition/`,
    {
      topic: connectTopic(),
      segment: lang,
      limit: 6,
    }
  );

  const loadMoreHandler = () => loadMore(`${checkUrl()}/api/v1/petition/?topic=${multiTopic}`, { segment: lang, limit: 5, offset: offset }, setPetitionsByTopic, setOffset, setEndOfList);

  return (
    <TopicsPageCtx.Provider value={{ petitionsByTopic, setPetitionsByTopic, loadMoreHandler, endOfList }}>
      {children}
    </TopicsPageCtx.Provider>
  );
};

export default TopicsCtx;
