import { useEffect, useState } from 'react';
import axios from 'axios';

const useTopicsPageData = (url, params) => {
  const [petitionsByTopic, setPetitionsByTopic] = useState([]);

  const fetchTopicsPageData = async () => {
    try {
      const res = await axios.get(url, { params });
      setPetitionsByTopic(res.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchTopicsPageData();
  }, []);

  return { petitionsByTopic, setPetitionsByTopic };
};

export default useTopicsPageData;
