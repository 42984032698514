import { createContext, useContext, useState } from 'react';
import { GlobalCtx } from './GlobalContext';
import useVictoriesPageData from '../hooks/useVictoriesPageData';
import { checkUrl } from '../components/Data';
import axios from 'axios';
import qs from 'qs';

export const VictoriesCtx = createContext();

const VictoriesContext = ({ children }) => {
  const { lang } = useContext(GlobalCtx);
  const { victoriesData, setVictoriesData } = useVictoriesPageData(`${checkUrl()}/api/v1/petition/`, { status: [1, 2, 3], segment: lang, limit: 6 });

  const [offset, setOffset] = useState(6);
  const [endOfList, setEndOfList] = useState(false);

  const loadMoreHandler = async () => {
    try {
        const res = await axios.get(`${checkUrl()}/api/v1/petition/?${qs.stringify({ status: [1, 2, 3], segment: lang, limit: 5, offset }, { arrayFormat: 'repeat' })}`);
        setVictoriesData((prevstate) => [...prevstate, ...res.data]);
        setOffset((prevstate) => prevstate + 5);
        res.data.length === 0 && setEndOfList(true);
    } catch(err) {
        console.log(err.message);
    }
  }

  return <VictoriesCtx.Provider value={{
    victoriesData,
    loadMoreHandler,
    endOfList
  }}>{children}</VictoriesCtx.Provider>;
};

export default VictoriesContext;
