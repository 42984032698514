import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

const useVictoriesPageData = (url, params) => {
  const [victoriesData, setVictoriesData] = useState([]);
  const fetchVictoriesData = async () => {
    try {
      const res = await axios.get(
        `${url}?${qs.stringify(params, { arrayFormat: 'repeat' })}`
      );
      setVictoriesData(res.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchVictoriesData();
  }, []);
  return { victoriesData, setVictoriesData };
};

export default useVictoriesPageData;
